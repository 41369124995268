import React from 'react'
import { Divider } from 'antd'
import Container from '../../components/Landing/Container'
import { isMobile } from 'react-device-detect'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import SEO from '../../components/seo'
import { InternalLink } from '../../components/BlogPost/Texts'

export default function Blog({ setCookieValue, data }) {
    const blogPostStyle: React.CSSProperties = isMobile
        ? {
              display: 'flex',
              flexDirection: 'column-reverse',
          }
        : {
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
          }
    const pcWrapperStyle = {
        display: 'grid',
        gridTemplateColumns: '1fr 5fr 1fr',
        marginLeft: 10,
        marginRight: 10,
    }

    return (
        <Container setCookieValue={setCookieValue} pathName="blog">
            <SEO title="Blog" />
            <div style={isMobile ? {} : pcWrapperStyle}>
                <div></div>
                <div style={{ marginTop: 20 }}>
                    <div style={blogPostStyle}>
                        <div style={{ margin: 10 }}>
                            <p style={{ fontSize: 16, color: 'gray' }}>Blogindlæg:</p>
                            <InternalLink to="/blog/geosnapshot-2" style={{ cursor: 'pointer' }}>
                                <h1 style={{ overflow: 'hidden' }}>
                                    GeoSnapshot har fået en opgradering
                                </h1>
                            </InternalLink>
                            <p style={{ fontSize: 16 }}>
                                ll33a.dk introducerer ”GeoSnapshot-dokumentation"
                                <br />- Nu kan du ikke blot registrere, hvor du er, men også
                                dokumentere det med et auto-genereret pdf-dokument
                                ”GeoSnapshot-dokumentation”.
                                <br />
                                - Dette dokument indeholder op til 6 forskellige parametre, der
                                optages i en tidsafgrænset GeoSnapshot-session.
                                <br />- Dokumentet kan når som helst verificeres vha.
                                SHA256-checksum metoden, så enhver med adgang til dokumentet kan
                                tjekke, at dokumentet er intakt og uændret.
                            </p>
                            <Divider />
                            <div style={{ textAlign: 'end', color: 'gray' }}>31/10/2023</div>
                        </div>
                        <div>
                            <Img
                                title="Skattestyrelsen logo"
                                alt="Skattestyrelsen logo"
                                className="landing-guide-illustration"
                                fluid={data.s3.childImageSharp.fluid}
                            />
                        </div>
                    </div>

                    <div style={blogPostStyle}>
                        <div style={{ margin: 10 }}>
                            <p style={{ fontSize: 16, color: 'gray' }}>Blogindlæg:</p>
                            <InternalLink
                                to="/blog/materiale-indkaldelse"
                                style={{ cursor: 'pointer' }}
                            >
                                <h1 style={{ overflow: 'hidden' }}>Materialeindkaldelse</h1>
                            </InternalLink>
                            <p style={{ fontSize: 16 }}>
                                “Skattestyrelsen har brug for oplysninger fra dig”
                                <br />
                                Dette er indledningen når Skattestyrelsens “inviterer” til
                                torske-gilde. Med andre ord er du udtaget til skattekontrol*, når du
                                modtager brev fra Skattestyrelsen med ovenstående overskrift.
                                <br />I denne artikel vil vi se nærmere på, hvilke oplysninger
                                Skattestyrelsen beder dig om at indsende, når du bliver udtaget til
                                skattekontrol af et skatteår, hvor du har oplyst* efter
                                ligningslovens § 33 A (herefter benævnt LL§33A)...
                            </p>
                            <Divider />
                            <div style={{ textAlign: 'end', color: 'gray' }}>08/12/2021</div>
                        </div>
                        <div>
                            <Img
                                title="Materiale indkaldelse"
                                alt="Materiale indkaldelse"
                                className="landing-guide-illustration"
                                fluid={data.s2.childImageSharp.fluid}
                            />
                        </div>
                    </div>

                    <div style={blogPostStyle}>
                        <div style={{ margin: 10 }}>
                            <p style={{ fontSize: 16, color: 'gray' }}>Blogindlæg:</p>
                            <InternalLink to="/blog/start-og-ophor" style={{ cursor: 'pointer' }}>
                                <h1 style={{ overflow: 'hidden' }}>
                                    Lempelsesperiodens start og ophør
                                </h1>
                            </InternalLink>
                            <p style={{ fontSize: 16 }}>
                                Hvordan er reglerne for start og ophør af en lempelsesperiode?
                                <br />
                                Det spørgsmål er vi på ll33a.dk ofte blevet spurgt om, og vi kan
                                godt forstå at man kan blive i tvivl. For der har været meget
                                usikkerhed omkring hvordan 6-måneders reglen skal forstås, og hvad
                                der konkret gælder omkring reglen. - Landsskatteretten og
                                Skatterådet har truffet to afgørelser vedrørende 6-måneders-reglen,
                                som har givet anledning til fortolkningstvivl...
                            </p>
                            <Divider />
                            <div style={{ textAlign: 'end', color: 'gray' }}>10/08/2021</div>
                        </div>
                        <div>
                            <Img
                                title="Skattestyrelsen logo"
                                alt="Skattestyrelsen logo"
                                className="landing-guide-illustration"
                                fluid={data.s1.childImageSharp.fluid}
                            />
                        </div>
                    </div>
                </div>
                <div></div>
            </div>
        </Container>
    )
}

export const query = graphql`
    query {
        s1: file(relativePath: { eq: "skattestyrelsen_logo_2.jpg" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        s2: file(relativePath: { eq: "mat-indkald.png" }) {
            childImageSharp {
                fluid(maxWidth: 300) {
                    ...GatsbyImageSharpFluid
                }
            }
        }

        s3: file(relativePath: { eq: "geosnapshot_feature_illustration.png" }) {
            childImageSharp {
                fluid(maxWidth: 600) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
